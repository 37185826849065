$black: black;
$primary: #e31e24;
$secondary: #2b2a29;
$spacer: 0.5rem;
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: true;
$grid-gutter-width: 15px;


@import "../node_modules/bootstrap/scss/bootstrap";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.batch-row td {
    position: relative;
}

.loading-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    text-align: center;
    background-color: white;
    z-index: 9999;
    opacity: .8;
    top: 0;
    left: 0;
    padding-top: calc(50vh - 10px);
    font-weight: bold;
  }

  .doctor-expertise-custom-field {
    display: block;
    position: relative;
    input {
      padding-right: 30px;
    }
    button {
      position: absolute;
      right: 5px;
      top: 6px;
      z-index: 99;
      background: none;
      border: none;
      opacity: 0.2;
    }
  }

  tbody.with-tiny-labels {
    td div.tiny-label {
      display: none;
      font-size: 8px;
      color: #333;
      text-align: right;
    }

    &.focused td div.tiny-label {
      display: block;
    }

    &.focused td {
      padding-top: 1px;
    }
  }